<template>
  <div>
    <b-card>
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="goods_no, goods_id, sku_id를 넣어주세요" v-model="form.search" @keypress.enter.prevent.stop="list" v-focus></b-form-input>
        <!--<b-input-group-append>
          <b-button variant="light" v-b-toggle.collapse1>검색조건 <i class="fa fa-chevron-down"></i></b-button>
        </b-input-group-append>-->
      </b-input-group>
      <shop-preset v-if="$R(['BALAANEER', 'E_GOODS_R'])" v-model="form.shop"></shop-preset>
      <div class="mb-2">
        <b-form-checkbox v-model="form.real_shop">특수 SHOP (0. 개인결제창, 204. CLEARANCE SALE, 2806. BALAAN REPAIR) 을 제외합니다</b-form-checkbox>
      </div>

      <b-row class="mb-2">
        <b-col cols="6" lg="3">
          <div><small class="mb-n2">정렬순서</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.orderby" :options="[
            {text: '정렬없음', value: 'no_order'},
            {text: '발란코드', value: 'goods_no'},
            {text: '높은가격', value: 'price_high'},
            {text: '낮은가격', value: 'price_low'},
          ]"></b-form-radio-group>
        </b-col>
<!--        <b-col v-if="$R('GOODS_R')" cols="6" lg="3">
          <div><small class="mb-n2">등록상태</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.goods_status" :options="[
            {text: '전체', value: 'ALL'},
            {text: 'Processing', value: 'processing'},
            {text: 'Registered', value: 'registered'},
            {text: 'Terminated', value: 'terminated'}
          ]"></b-form-radio-group>
        </b-col>-->
        <b-col cols="6" lg="2">
          <div><small class="mb-n2">연동형태</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.manual" :options="[
            {text: '전체', value: 'ALL'},
            {text: '자동(FEED)', value: 'auto'},
            {text: '파트너관리', value: 'manual'}
          ]"></b-form-radio-group>
        </b-col>
        <b-col cols="6" lg="2">
          <div><small class="mb-n2">재고상태</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.stock_status" :options="[
            {text: '전체', value: 'ALL'},
            {text: '재고있음', value: 'normal'},
            {text: '품절', value: 'runout'}
          ]"></b-form-radio-group>
        </b-col>
        <b-col v-if="$R('GOODS_R')" cols="6" lg="2">
          <div><small class="mb-n2">노출상태</small></div>
          <b-form-radio-group class="col-form-label" v-model="form.display_status" :options="[
            {text: '전체', value: 'ALL'},
            {text: '노출', value: 'view'},
            {text: '미노출', value: 'notview'}
          ]"></b-form-radio-group>
        </b-col>
      </b-row>

      <b-collapse id="collapse1" v-model="collapse.detail">
        <b-row class="mb-2">
          <b-col cols="12" lg="6">
            <brand-preset class="mb-3" v-model="form.brand" :hideDisabled="true"></brand-preset>
          </b-col>
          <b-col cols="12" lg="6">
            <category-preset class="mb-3" v-model="form.category"></category-preset>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col lg="2">
            <small>가격조건</small><br/>
            <b-input-group>
              <b-form-input type="number" placeholder="최소가" v-model.number="form.min"></b-form-input>
              <b-input-group-append>
                <b-button variant="primary"><i class="fa fa-exchange"></i></b-button>
              </b-input-group-append>
              <b-form-input type="number" placeholder="최대가" v-model.number="form.max"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col md="2">
            <small>SKU ID 중간검색(다른 조건과 함께 사용해주세요)</small>
            <b-form-input type="text" v-model="form.sku_id"></b-form-input>
          </b-col>
          <b-col md="2">
            <small>총 재고합이 n 개 이상</small>
            <b-form-input type="text" placeholder="전체 재고가 이 숫자 이상입니다" v-model.number="form.tot_stock"></b-form-input>
          </b-col>
          <b-col md="2">
            <small>재고가 n 개 이상인 옵션이 존재</small>
            <b-form-input type="text" placeholder="options 의 재고가 n개 이상인 옵션이 존재합니다" v-model.number="form.opt_stock"></b-form-input>
          </b-col>
          <b-col md="2">
            <small>goods_no 범위선택</small><br/>
            <b-form inline>
              <b-form-input class="text-center w-100px" v-model.number="form.goods_no_from" placeholder="시작"></b-form-input>
              ~
              <b-form-input class="text-center w-100px" v-model.number="form.goods_no_to" placeholder="끝"></b-form-input>
            </b-form>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col md="3">
            <small>생성시각</small><br/>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.created_from" placeholder="2020-04-01 00:00:00"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.created_to" placeholder="2020-04-01 00:00:00"></b-form-input>
            </b-form>
          </b-col>
          <!--<b-col md="4">
            <small>송출시각</small><br/>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.registered_from" placeholder="2020-04-01 00:00:00"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.registered_to" placeholder="2020-04-01 00:00:00"></b-form-input>
            </b-form>
          </b-col>-->
          <b-col md="3">
            <small>최근수정시각</small><br/>
            <b-form inline>
              <b-form-input class="text-center" v-model="form.updated_from" placeholder="2020-04-01 00:00:00"></b-form-input>
              ~
              <b-form-input class="text-center" v-model="form.updated_to" placeholder="2020-04-01 00:00:00"></b-form-input>
            </b-form>
          </b-col>
          <b-col md="2">
            <small>오늘도착 가능상품</small><br/>
            <b-form-radio-group class="col-form-label" v-model="form.oneday_delivery" :options="[
                {text: '전체', value: 'ALL'},
                {text: '오늘도착', value: 'y'},
              ]"></b-form-radio-group>
          </b-col>
          <b-col md="2">
            <small>배송유형(Both Shop 전용)</small><br/>
            <b-form-radio-group class="col-form-label" v-model="form.delivery_type" :options="[
                {text: '전체', value: 'ALL'},
                {text: '해외', value: '해외'},
                {text: '국내', value: '국내'},
              ]"></b-form-radio-group>
          </b-col>
          <b-col md="2">
            <small>이미지체크</small><br/>
            <b-form-radio-group class="col-form-label" v-model="form.image_check" :options="[
                {text: '전체', value: 'ALL'},
                {text: '이미지없음', value: 'n'},
              ]"></b-form-radio-group>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col md="3">
            <small>Naver 최저가 검색결과(6시간 이내 데이터)</small><br/>
            <div class="col-form-label">
              <b-form-checkbox v-model="form.has_naver">Naver 최저가 검색결과 존재</b-form-checkbox>
            </div>
          </b-col>
          <b-col md="3" v-if="form.has_naver">
            <small>Naver 최저가 랭킹(1 이면 최저가)</small><br/>
            <b-form inline>
              <b-input-group>
                <b-form-input class="text-center w-50px" v-model.number="form.naver_rank_from"></b-form-input>
                <b-input-group-append>
                  <b-input-group-text>~</b-input-group-text>
                </b-input-group-append>
                <b-form-input class="text-center w-50px" v-model.number="form.naver_rank_to"></b-form-input>
              </b-input-group>
              <b-form-checkbox class="ml-2" v-model="form.naver_has_other_cnt">타사 상품 존재</b-form-checkbox>
              <template v-if="form.naver_has_other_cnt">
                <b-form-input class="text-center w-50px mx-2" v-model.number="form.naver_other_cnt"></b-form-input>
                개 이상
              </template>
            </b-form>
          </b-col>
          <b-col md="3" v-if="form.has_naver">
            <small>Naver 가격차이(%) 범위 선택(음수일 경우 발란 상품이 비싼 케이스)</small><br/>
            <b-form inline>
              <b-input-group>
                <b-form-input class="text-right w-65px" placeholder="%" v-model.number="form.naver_price_diff_per_from"></b-form-input>
                <b-input-group-append>
                  <b-input-group-text>~</b-input-group-text>
                </b-input-group-append>
                <b-form-input class="text-right w-65px" placeholder="%" v-model.number="form.naver_price_diff_per_to"></b-form-input>
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col>
            <small>컬러</small><br/>
            <div class="clearfix">
              <b-button class="pull-left mr-2" size="sm" variant="primary" @click="e=>{ if (form.major_color.length === $C.COLORS.length) form.major_color = []; else form.major_color = $C.COLORS.map(e=>e.name); }">전체</b-button>
              <b-form-checkbox-group class="pull-left mt-1" name="group" v-model="form.major_color">
                <b-form-checkbox v-for="c in $C.COLORS" :key="c.name" :value="c.name">
                  <div class="d-inline-block" :style="{backgroundColor:'#'+c.color, width:'22px', height:'22px'}" v-b-tooltip="c.name_ko"></div>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col lg="3">
            <small>goods_no</small>
            <b-form-textarea id="goodsNo" :rows="2" v-model="form.goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>제외할 goods_no</small>
            <b-form-textarea id="goodsNoExclude" :rows="2" v-model="form.goods_no_exclude" placeholder="제외할 goods_no를 입력해주세요"></b-form-textarea>
          </b-col>
          <b-col lg="3">
            <small>한 번에 가져올 상품 수</small><br/>
            <b-form-input id="limit" type="text" placeholder="한 번에 가져올 상품 수" v-model.number="form.limit"></b-form-input>
          </b-col>
          <b-col lg="3">
            <small>goods_id</small>
            <b-form-textarea id="goodsId" :rows="2" v-model="form.goods_id" placeholder="goods_id를 입력해주세요"></b-form-textarea>
          </b-col>
        </b-row>

        <b-form-checkbox v-if="$R('BALAANEER')" class="mb-3" v-model="form.minimal">필수정보만 가져옵니다(Update 등을 빠르게 하기 위해)</b-form-checkbox>
        <b-form-checkbox v-if="$R('BALAANEER')" class="mb-3" v-model="form.godo">godo몰 정보를 가져옵니다</b-form-checkbox>
      </b-collapse>
      <b-button class="m-1" variant="primary" @click="list" :disabled="busy.list">검색<b-spinner class="mr-1" small v-if="busy.list"></b-spinner></b-button>
      <b-button class="m-1" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>
    </b-card>

    <confirmed-modal ref="confirmedModal" collection="terminated" v-bind="{modal, momentBiz, shopMap, targetMap}"></confirmed-modal>

    <b-modal title="노출상태 일괄변경" size="lg" v-model="modal.displayStatus" @ok="setDisplayStatus">
      <b-alert show variant="info">총 {{items.list.filter(e=>e.selected).length}} 건의 상품의 상태를 변경합니다</b-alert>
      <label>노출상태(display_status)</label>
      <b-form-radio-group class="col-form-label" v-model="displayStatus" :options="[
        {text: '노출', value: 'view'},
        {text: '미노출', value: 'notview'}
      ]"></b-form-radio-group>
      <br/>
      <label>즉시반영</label>
      <b-form-radio-group class="col-form-label" v-model="displayStatusRelay" :options="[
        {text: '반영', value: true},
        {text: '미반영', value: false}
      ]"></b-form-radio-group>
      <small class="text-muted">즉시반영을 선택하시면 바로 외부몰에 전파됩니다.</small>
      <br/>
      <label>변경사유</label>
      <b-input v-model="displayStatusReason"></b-input>
    </b-modal>

    <!--    <modal-base :diffListModal="modal.diffList" :diff="diff" :type="'diff'"></modal-base>-->
    <!--    <b-modal title="수정이력 확인" size="lg" v-model="modal.diffList" ok-title="닫기" ok-only>-->
    <!--      <div v-if="diff">-->
    <!--        <template v-for="d in diff.slice().reverse()">-->
    <!--          <div class="clearfix">-->
    <!--            <div class="pull-right">-->
    <!--              <b-badge class="mr-1" variant="success">{{d._at}}</b-badge>-->
    <!--              <b-badge class="" variant="light">{{d._dt}}</b-badge>-->
    <!--              &lt;!&ndash; 관리자이거나 본인이면 rollback 버튼 &ndash;&gt;-->
    <!--              <b-button size="sm" class="rollback" @click="rollbackDiff(d, item)">되돌리기</b-button>-->
    <!--            </div>-->
    <!--            <div><b-badge variant="primary">{{d._name}}</b-badge></div>-->
    <!--            <span v-html="formatDiff(d)"></span>-->
    <!--          </div>-->
    <!--          <hr/>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--      <div v-else class="text-center">-->
    <!--        수정이력이 없습니다-->
    <!--      </div>-->
    <!--    </b-modal>-->

    <b-modal title="이미지 재처리" size="lg" v-model="modal.image" @ok="imageProcessing()">
      <b-alert show variant="info">총 {{items.list.filter(e=>e.selected).length}} 건의 상품의 이미지를 재처리합니다</b-alert>
      <ul>
        <li>기본 룰 : 크롬 확장프로그램 이미지 치환 -> 최초 상품 등록시의 원본 이미지 -> 현재의 원본 이미지 순으로 체크하여 먼저 발견된 이미지로 처리됩니다.</li>
        <li>최초 상품 등록시의 원본 이미지와 현재의 원본 이미지가 달라서 바뀐 이미지로 처리하려면 아래에서 선택해주세요</li>
        <li>원본 이미지의 순서와 현재 이미지의 순서가 다르다면 이미지 재처리 후 다시 바꿔줘야 합니다</li>
        <li>긴 이미지(SHOP 설정 참고)가 발견될 때, 상세설명의 이미지도 교체됩니다</li>
      </ul>
      <b-form-radio-group v-model="imageIntent" :options="[
        {text: '기본 룰', value: 'default'},
        {text: '크롬 이미지 치환 혹은 네이버용 백그라운드 처리 이미지 결과를 무시하고 최초 등록시의 원본 이미지로 재처리', value: 'useOriginal'},
        {text: '크롬 이미지 치환 혹은 네이버용 백그라운드 처리 이미지 결과를 무시하고 현재의 원본(mapped) 이미지로 재처리', value: 'useMapped'},
      ]" stacked>
      </b-form-radio-group>
    </b-modal>

    <b-modal title="발란몰로 싱크" size="lg" v-model="modal.godo" @ok="syncGodo()">
      <b-alert show variant="info">총 {{items.list.filter(e=>e.selected).length}} 건의 상품을 발란몰로 싱크합니다</b-alert>
      <b-form-checkbox v-model="syncForce">특수샵(204) 싱크</b-form-checkbox>
      <span>싱크할 필드 선택</span>
      <b-button class="ml-1" size="sm" variant="primary" @click="selectedGodoCols = godoCols.length === selectedGodoCols.length ? [] : godoCols.map(e=>e.value)">전체선택/해제</b-button>
      <br/>
      <b-form-checkbox v-for="c in godoCols" v-model="selectedGodoCols" :key="c.value" :value="c.value">{{c.text}} ({{c.value}}) : {{c.desc}}</b-form-checkbox>
    </b-modal>

    <div class="clearfix">
<!--      <div v-if="$R('GOODS_X')" class="pull-left">
        <b-button class="m-1" variant="info" @click="setDisplayStatusModal">노출상태일괄변경</b-button>
        <b-button class="m-1" variant="primary" @click="imageProcessingModal">이미지 재처리</b-button>
        <b-button class="m-1" variant="success" @click="syncStockMulti">재고 일괄갱신</b-button>
      </div>-->
      <b-button class="m-1" variant="danger" @click="terminateGoods" :disabled="busy.terminateGoods">발란몰, SS로 전파
        <b-spinner class="ml-1" small v-if="busy.terminateGoods"></b-spinner>
      </b-button>
      <b-button class="m-1" variant="danger" @click="removeGdGoods" :disabled="busy.removeGdGoods">발란몰 상품 제거
        <b-spinner class="ml-1" small v-if="busy.removeGdGoods"></b-spinner>
      </b-button>

      <div class="pull-right">

        <b-button class="m-1" variant="light" @click="copyGoodsNo">발란코드 복사</b-button>
        <xlsx :types="['xlsx']" :preFunc="preDown" :data="items.list" :labels="xlsx.labels" :keys="xlsx.keys" name="Confirmed"></xlsx>
        <!--<b-button class="m-1" variant="success" @click="down('xlsx')">XLSX</b-button>
        <b-button class="m-1" variant="success" @click="down('txt')">Text</b-button>-->

        <b-button-group class="m-1">
          <b-button :variant="itemMode === 'list' ? 'dark' : 'light'" @click="itemMode = 'list'"><i class="fa fa-list"></i></b-button>
          <b-button :variant="itemMode === 'pic' ? 'dark' : 'light'" @click="itemMode = 'pic'"><i class="fa fa-picture-o"></i></b-button>
        </b-button-group>
      </div>
    </div>

    <c-table v-if="itemMode === 'list'" :table-data="items.list" :fields="fields" :perPage.sync="perPage" :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" :caption="items.list.length + ' 개 상품'" @btn-clicked="btnAction" @get-more="list(true)"></c-table>

    <div v-else class="bg-white rounded p-2">
      <div class="clearfix">
        <div class="pull-right">
          <b-button variant="primary" @click="_=>{items.list.filter(e=>(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)).forEach(e=>e.selected = true);recalcPicFilteredCnt();$forceUpdate()}">전체선택</b-button>
          <b-button variant="warning" @click="_=>{items.list.filter(e=>(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)).forEach(e=>e.selected = false);recalcPicFilteredCnt();$forceUpdate()}">선택해제</b-button>
        </div>
        <b-form inline>
          <b-button-group>
            <b-button :variant="picGroup === 'ALL' ? 'primary' : 'light'" @click="picGroup = 'ALL'">
              {{items.list.length}} 개 전체 아이템
            </b-button>
            <b-button :variant="picGroup === 'selected' ? 'success' : 'light'" @click="picGroup = 'selected'">
              {{items.list.filter(e=>e.selected).length}} 개 선택된 아이템
            </b-button>
          </b-button-group>
          <b-input-group class="ml-1">
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="fa fa-filter"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="picFilter"></b-form-input>
            <b-input-group-append v-if="picFilter">
              <b-input-group-text>
                {{picFilteredCnt}} 개
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class="ml-1">
            <b-input-group-prepend><b-input-group-text>이미지 너비</b-input-group-text></b-input-group-prepend>
            <b-form-input class="w-65px text-center" type="number" v-model.number="picWidth"></b-form-input>
            <b-input-group-append><b-input-group-text>px</b-input-group-text></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=175">175</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=195">195</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=225">225</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=265">265</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=318">318</b-button></b-input-group-append>
            <b-input-group-append><b-button @click="picWidth=400">400</b-button></b-input-group-append>
          </b-input-group>
          <b-checkbox class="ml-2" v-model="picInfoTop">상단정보</b-checkbox>
          <b-checkbox class="ml-2" v-model="picInfoBottom">하단정보</b-checkbox>
        </b-form>
      </div>
      <div class="flex-row flex-wrap d-flex">
        <div v-for="e of items.list" v-if="(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)"
             class="flex-grow-0 m-1 position-relative" :style="{width:picWidth+'px', padding:'3px', border:e.selected ? '4px solid #20a8d8' : '1px solid #eee'}">
          <!--<b-checkbox v-model="e.selected" class="position-absolute"></b-checkbox>-->
          <div class="position-absolute text-right" style="right:0;line-height:15px">
            <!--<b-badge variant="success">{{e.shop_id}}. {{e.shop}}</b-badge><br/>-->
            <a :href="`/#/goods/${e.goods_no}`" target="_blank" class="badge badge-primary">{{e.goods_no}} <i class="fa fa-external-link"></i></a>
            <div v-if="picInfoTop">
              <b-badge variant="warning">{{e.brand_nm}}</b-badge><br/>
              <template v-if="e.consumer !== e.price">
                <b-badge variant="dark"><del>{{$utils.comma(e.consumer)}}</del> → {{$utils.comma(e.price)}} 원</b-badge>
              </template>
              <template v-else>
                <b-badge variant="dark">{{$utils.comma(e.price)}} 원</b-badge>
              </template>
            </div>
          </div>
          <img :src="e.img_urls[0]" class="w-100" @click="picClick(e)"/>
          <div v-if="picInfoBottom" class="info-bottom" style="line-height:18px">
            <b-badge variant="success">{{e.shop_id}}. {{e.shop}}</b-badge><br/>
            <template v-if="picWidth < 250">
              <div class="text-truncate overflow-hidden fs-11 bold" :title="e.goods_nm">{{e.goods_nm}}</div>
              <div class="text-truncate overflow-hidden fs-11" :title="e.org_name">{{e.org_name}}</div>
            </template>
            <template v-else>
              <div class="text-truncate overflow-hidden bold" :title="e.goods_nm">{{e.goods_nm}}</div>
              <div class="text-truncate overflow-hidden" :title="e.org_name">{{e.org_name}}</div>
            </template>
            <div class="clearfix mb-1">
              <div class="pull-left text-truncate overflow-hidden">
                <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${e.sku_id}`" class="badge badge-info" target="_blank">{{e.sku_id}} <i class="fa fa-external-link"></i></a>
              </div>
              <div class="pull-right">
                <b-badge variant="light">{{e.tot_stock}} 개</b-badge>
                <!--<template v-if="e.consumer !== e.price">
                  <b-badge variant="dark"><del>{{$utils.comma(e.consumer)}}</del> → {{$utils.comma(e.price)}} 원</b-badge>
                </template>
                <template v-else>
                  <b-badge variant="dark">{{$utils.comma(e.price)}} 원</b-badge>
                </template>-->
                <br/>
              </div>
            </div>
            <span v-if="picWidth < 200">
              <b-badge>{{e.options[0] && e.options[0].optnm || ''}}</b-badge>
              <b-badge v-for="opt of e.options.filter(e=>e.stock>0 && !e.not_found)" variant="light">{{opt.Size}}</b-badge>
            </span>
            <div v-else class="flex-wrap d-flex">
              <b-badge>{{e.options[0] && e.options[0].optnm || ''}}</b-badge>
              <div v-for="opt of e.options.filter(e=>e.stock>0 && !e.not_found)" class="flex-grow-0">
                <b-badge variant="light">{{opt.Size}}</b-badge>
                <b-badge variant="success" class="mr-1">{{opt.stock}}</b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasMore.list" class="text-center py-3">
        <b-button variant="primary" size="lg" @click="list(true)">더 가져오기</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import shopPreset from '@/views/ShopPreset.vue'
import brandPreset from '@/views/BrandPreset.vue'
import categoryPreset from '@/views/CategoryPreset.vue'
import confirmedModal from '@/views/goods/ConfirmedModal.vue'
import xlsx from '@/views/modules/Xlsx.vue'
import {getMeta, postJson} from '@/shared/api'
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp'
import {MALL_GOODS_LINK, ORDER_STATUS_CODE, CONFIRMED_COLUMNS, GODO_COL_TARGET} from 'balaan_constants'
import * as moment from 'moment-timezone';
import * as momentBiz from 'moment-business-days';

const infoMap = {
  'shop_id': 'shop_id',
  'brand_nm': '브랜드',
  'goods_nm': '상품명',
  'sku_id': '모델명',
  'season': '시즌',
  'composition': '소재',
  'measurements': '사이즈',
  'color': '컬러',
  'description': '상품설명',
};
const targetMap = { // 상품링크 매칭에 사용됨
  godo: '고도몰',
  lotteimall: '롯데아이몰',
  smartstore: '스토어팜(발란)',
};

export default {
  name: 'Terminated',
  title: '종료상품조회',
  components: {shopPreset, brandPreset, categoryPreset, xlsx, confirmedModal},
  data() {
    return {
      utils, MALL_GOODS_LINK, ORDER_STATUS_CODE, CONFIRMED_COLUMNS, GODO_COL_TARGET, momentBiz, targetMap,
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      priceMetaMap: {},
      godoCols: [],
      selectedGodoCols: [],
      syncForce: false,
      form: {
        search: '',
        shop: [],
        brand: [],
        category: [],
        real_shop: false,
        orderby: 'goods_no',
        goods_status: 'ALL',
        manual: 'ALL',
        stock_status: 'ALL',
        display_status: 'ALL',
        sku_id: '',
        tot_stock: '',
        opt_stock: '',
        min: '',
        max: '',
        limit: 100,
        skip: 0,
        created_from: '',
        created_to: '',
        updated_from: '',
        updated_to: '',
        goods_no_from: '',
        goods_no_to: '',
        oneday_delivery: 'ALL',
        delivery_type: 'ALL',
        image_check: 'ALL',
        has_naver: false,
        naver_rank_from: '',
        naver_rank_to: '',
        naver_has_other_cnt: false,
        naver_other_cnt: '',
        naver_price_diff_per_from: '',
        naver_price_diff_per_to: '',
        major_color: this.$C.COLORS.map(e=>e.name),
        goods_no: '',
        goods_id: '',
        goods_no_exclude: '',
        minimal: false,
        godo: false,
        collName: 'terminated',
      },
      collapse: {detail:false},
      lastBody: {list:{}},
      items: {list:[]},
      busy: {list: false, listmore: false, syncStockMulti: false, terminateGoods: false, removeGdGoods: false},
      hasMore: {list:false},
      ac: {list: null}, // abortController

      title: '',
      // item: {},
      itemAttr: {},
      imageIntent: 'default',
      fields: [
        'selected',
        // {key: 'html1', label:'발란코드<br/><span class="badge badge-light">카테고리코드</span>', style:{width:'100px'}, class: 'text-center'},
        {key: '_img60', label: '이미지', class: 'text-center'},
        {key: 'html2', label: `상품정보<br/><span class="badge badge-success">판매 가능수량</span><span class="badge badge-primary">SHOP 원본 수량</span><span class="badge badge-info">결제된 주문수량</span>`
            + `<span class="badge badge-warning">10분이내 주문 미결제수량</span>`, style: {minWidth: '250px'}},
        {key: 'html5', label:'<span class="badge badge-light">연동형태</span><br/><span class="badge badge-light">sku</span><br/><span class="badge badge-light">goods_id</span>', class: 'text-center'},
        {key: 'html4', label: '외부몰', class: 'text-center', style: {minWidth: '60px'}},
        {key: 'html3', label: '소비자가<br/>판매가<br/><span class="">(할인율)</span>', class: 'text-right w-100px'},
        {key: 'html6', label: '재고', style: {width: '50px'}, class: 'text-center'},
        {
          key: 'html8',
          label: '<span class="badge badge-primary">Naver Rank</span><br/><span class="badge alert-info">최저가 차이</span><br/><span class="badge alert-success">타사 상품수</span>',
          style: {width: '80px'},
          class: 'text-center'
        },
        {key: 'html7', label: '상태', class: 'text-center'},
        {key: '_actions', label: '상세', style: {width: '55px', textAlign: 'center'}, buttons: [{label: '상세', event: 'show_modal'}]},
      ],
      perPage: 20,
      itemMode: 'list',
      picFilter: '',
      picFilteredCnt: 0,
      picGroup: 'ALL',
      picWidth: 175,
      picInfoTop: true,
      picInfoBottom: true,
      modal: {detail:false, displayStatus:false, diffList:false, image:false, field:false, godo:false},
      editMode: false,
      diff: null,
      priceHtml: '',
      displayStatus: 'view',
      displayStatusRelay: true,
      displayStatusReason: '',
      today: moment().format('YYYY-MM-DD'),
      xlsx: {
        keys: [],
        labels: [],
      },
    }
  },
  sockets: {
    confirmed_query(q) {
      console.log(q);
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'itemMode,picWidth,perPage');

    let meta = await getMeta('shop,brand,category,holiday,price');

    // 공휴일 설정
    let holidays = meta.holiday.map(e=>{
      if (e.require) return momentBiz().format('YYYY-') + e.date;
      return e.date;
    });
    // 작년, 내년도 추가한다
    holidays = holidays.concat(meta.holiday.filter(e=>e.require).map(e=>momentBiz().add(1, 'year').format('YYYY-') + e.date));
    holidays = holidays.concat(meta.holiday.filter(e=>e.require).map(e=>momentBiz().subtract(1, 'year').format('YYYY-') + e.date));
    momentBiz.updateLocale('kr', {
      holidays: holidays,
      holidayFormat: 'YYYY-MM-DD'
    });
    window.moment = momentBiz;

    meta.shop.forEach(s => {
      s.value = s.boutique;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    }); // use_yn 무관 일단 정보는 필요
    this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);

    this.brand = meta.brand.map(e=>{
      return this.brandMap[e.brand_no] = {...e, value:e.brand_no, label:`${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a,b)=>a.label.localeCompare(b.label));

    this.category = meta.category.map(e=>{
      return this.categoryMap[e.category] = {...e, value:e.category, label:`${e.category} (${e.category_nm})`};
    }).sort((a,b)=>(a.value.length - b.value.length)*10 + a.value.localeCompare(b.value));

    meta.price.forEach(e => {
      this.priceMetaMap[e.price_meta_name] = e.price_meta_value;
    });

    if (Object.keys(this.$route.query).length) {
      Object.keys(this.$route.query).forEach(k => {
        if (this.$route.query[k] != null) {
          let v = this.$route.query[k];
          if (~'tot_stock,opt_stock,min,max,goods_no_from,goods_no_to,naver_rank_from,naver_rank_to,naver_other_cnt,naver_price_diff_per_from,naver_price_diff_per_to'.split(',').indexOf(k)) {
            this.form[k] = +v;
          } else if (~'real_shop,has_naver,naver_has_other_cnt,minimal,godo'.split(',').indexOf(k)) {
            this.form[k] = !(!v || v === 'false');
          } else if (k === '$shop') {
            let arr = v.split(',');
            this.form.shop = arr.map(e => this.shopMap[e]);
          } else if (k === '$category') {
            let arr = v.split(',');
            this.form.category = arr.map(e => this.categoryMap[e]);
          } else if (k === '$brand') {
            let arr = v.split(',');
            this.form.brand = arr.map(e => this.brandMap[e]);
          } else if (k === 'collapse.detail') {
            this.collapse.detail = !(!v || v === 'false');
          } else if (k === 'itemMode') {
            this.itemMode = v;
          } else if (~Object.keys(this.form).indexOf(k)) {
            this.form[k] = v;
          }
        }
      });
    }

    // 발란몰로 보낼 수 있는 컬럼 정리
    Object.entries(GODO_COL_TARGET).forEach(([k,v])=>{
      let col = CONFIRMED_COLUMNS[k];
      if (col) {
        this.godoCols.push({value:k, text:col.name, desc:col.desc});
      }
    });

    const no = this.$route.params.no;
    if (no) {
      this.form.search = no;
      this.form.display_status = 'ALL';
      await this.list();
      let item = this.items.list.filter(e=>e.goods_no === +no)[0];
      if (item) this.showModal({item});
      return;
    }
    this.list();
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e=>e).forEach(e=>e.abort());
    this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
  },
  watch: {
    picFilter(v) {
      if (v) {
        this.items.list.forEach(e=>{
          let uv = v.toUpperCase();
          e.filtered = ~e.goods_nm.toUpperCase().indexOf(uv)
            || ~e.sku_id.toUpperCase().indexOf(uv)
            || ~e.goods_id.toUpperCase().indexOf(uv)
            || ~(e.goods_no+'').toUpperCase().indexOf(uv)
            || ~(e.org_name || '').toUpperCase().indexOf(uv)
            || ~(e.brand_nm || '').toUpperCase().indexOf(uv)
            || ~(e.brand_nm_kr || '').toUpperCase().indexOf(uv)
            || ~(e.category_nm || '').toUpperCase().indexOf(uv)
        });
        this.picFilteredCnt = this.items.list.filter(e=>e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
      }
    },
    picGroup(v) {
      this.picFilteredCnt = this.items.list.filter(e=>e.filtered && (v === 'ALL' || e.selected)).length;
    },
    itemMode() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    picWidth() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    perPage() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    }
  },
  methods: {
    async list(more) {
      let form = this.form;
      if (form.limit >= 10000 && !form.minimal) return alert('10000개 이상을 검색할때는 필수정보만 가져오기를 선택해주세요');

      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e=>e.shop_id); // 전체 선택일 경우 비우기
      let brand = form.brand.map(e=>e.value);
      let category = form.category.map(e=>e.value);
      let goods_no = this.form.goods_no ? this.form.goods_no.trim().split(/\D+/g).map(e=>+e) : [];
      let goods_no_exclude = this.form.goods_no_exclude ? this.form.goods_no_exclude.trim().split(/\D+/g).map(e=>+e) : [];
      let goods_id = this.form.goods_id ? this.form.goods_id.trim().split(/\r?\n/g).map(e=>e.trim()) : [];
      if (goods_no.length) {
        goods_no = goods_no.filter(e=>!~goods_no_exclude.indexOf(e));
        goods_no_exclude = [];
      }
      let body = {...form, shop, brand, category, goods_no_include: goods_no, goods_no_exclude, goods_id_include: goods_id};
      let j = await this.$api.postTable(this, '/goods/terminated', body, {more, fnAssign:this.assignTableData});
      // 검색결과중 검색필드와 일치하는 데이터가 있다면 url 을 바꿔준다.
      if (j && j.list.filter(e=>e.goods_no+'' === this.form.search).length && location.hash === '#/goods') {
        history.replaceState(null, null, location.origin + '/#/goods/' + this.form.search);
      }
    },
    assignTableData(e) {
      if (!e.goods_id) {
        e.html2 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}</a>
            <a href="${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goods_no}" class="badge badge-info" target="_blank">${e.goods_no}</a><br/>`;
        return;
      } // minimal 인 경우
      if (e.category) {
        let cates = [];
        for (let i = 3; i <= e.category.length; i += 3) {
          if (e.category.substring(i - 3, i) === '001') cates.push('전체');
          else cates.push(this.categoryMap[e.category.substring(0, i)] && this.categoryMap[e.category.substring(0, i)].category_nm || '?');
        }
        e.cate = cates.join(' > ');
      } else if (e.brand_no !== 0) {
        // 개인결제창이 아닌 경우
        console.log('카테고리 없음 : ', e);
      }
      if (!e.consumer) e.consumer = e.price;

      // 배송유형 및 일자 확정
      let shop = this.shopMap[e.shop_id];
      if (shop) {
        e.logistics = shop.logistics;
        if (shop.delivery_type !== 'both') {
          e.delivery_type = {abroad:'해외', domestic:'국내'}[shop.delivery_type];
          e.delivery_day = shop.delivery_day;
        } else {
          if (e.delivery_type === '국내') {
            e.delivery_day = shop.delivery_day;
          } else {
            e.delivery_day = shop.delivery_day_abroad || 6;
          }
        }
      } else {
        e.delivery_day = 6; // 이전부터 있던 하드코딩, 6일인 이유는?
      }

      e.img = e.img_urls[0];
      // e.html1 = `<a href="${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goods_no}" target="_blank">${e.goods_no}</a><br/>`
      //   + `<span class="badge badge-light">${e.category}</span>`;
      e.html2 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop} <i class="fa fa-external-link"></i></a> <span class="badge badge-warning">${e.brand_nm}</span>`
        + ` <span class="badge badge-light" title="${e.category}">${e.cate||''}</span> <a href="${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goods_no}" class="badge badge-info" target="_blank">${e.goods_no} <i class="fa fa-external-link"></i></a>`
        + `<span class="badge alert-${e.delivery_type === '해외' ? 'success' : 'info'} ml-1">${e.delivery_type} ${e.delivery_day}일</span>`
        + (e.oneday_delivery === 'Y' ? `<span class="badge badge-danger ml-1">오늘도착 가능</span>` : '')
        + `<br/>`
        + `${e.goods_nm}<br/><small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small> `
        + `${e.options.filter(e=>!e.not_found).map(e=>
          `<span class="badge badge-light">${e.Size}</span><span class="badge badge-${e.stock>0?'success':'secondary'}" title="판매 가능수량">${e.stock}</span>`
          + (e.shop_stock != null ? `<span class="badge badge-${e.shop_stock>0?'primary':'secondary'}" title="SHOP 의 원본 수량">${e.shop_stock}</span>` : '')
          + (e.order_stock ? `<span class="badge badge-info" title="결제된 주문수량">${e.order_stock}</span>` : '')
          + (e.prepared_stock ? `<span class="badge badge-warning" title="결제 전 주문수량">${e.prepared_stock}</span>` : '')
        ).join(', ')}`;
      e.html3 = (e.consumer === e.price ? `<span class="grey-b3">${this.$utils.comma(e.consumer)}</span>` : `<del>${this.$utils.comma(e.consumer)}</del>`) + '<br/>' +
        `${e.price ? this.$utils.comma(e.price) : ''}<br/>` + (e.consumer === e.price ? '' : `(${100 - Math.round(e.price / e.consumer * 100)}%)`);
      e.html6 = e.tot_stock;
      e.html4 = e.links.map(s=>`<span class="badge badge-primary">${s.mall}</span>&nbsp;`
        + (targetMap[s.mall] ? `<a href="${MALL_GOODS_LINK[targetMap[s.mall]]}${s.mall_id}" target="_blank" class="badge badge-info" title="${s._cdt}">${s.mall_id} <i class="fa fa-external-link"></i></a>` : `<span class="badge badge-secondary">${s.mall_id}</span>`)
      ).join('<br/>');
      e.html5 = (e.manual ? `<span class="badge badge-danger">파트너관리</span><br/>` : '')
        + (e.sku_id ? `<a href="https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${encodeURIComponent(e.sku_id)}" target="_blank" class="badge badge-success">${e.sku_id} <i class="fa fa-external-link"></i></a>` : '') + `<br/>`
        + (e.crawl_url && e.crawl_url.startsWith('http') ? `<a href="${`https://hub.balaan.io/r?u=${encodeURIComponent(e.crawl_url)}`}" target="_blank" class="badge badge-info">${e.goods_id} <i class="fa fa-external-link"></i></a>` : `<span class="badge badge-light">${e.goods_id}</span>`) + '<br/>'
      ;
      e.html7 = [e.goods_status, e.stock_status, e.display_status].map(e=>this.$utils.badge(e)).join('<br/>');
      e.html8 = e.naver
        ? `<span class="badge badge-${e.naver.rank === 1 ? (e.naver.other_product_cnt > 0 ? 'primary' : 'light') : 'warning'}">${e.naver.rank} 위</span><br/>`
        + `<span class="badge alert-${e.naver.rank === 1 ? 'info' : 'warning'}">${e.naver.price_diff_per} %</span><br/>`
        + (e.naver.rank === 1 && e.naver.other_product_cnt ? `<span class="badge alert-success">${e.naver.other_product_cnt} 개</span>` : '')
        : '';
      e._html = `${Object.entries(infoMap).map(([k,v])=>`${v}: ${e[k]}`).join('<br/>')}<br/>${e.img_urls.map(i=>`<img src="${i}" style="height:100px"/>`).join('')}`;
    },

    showModal(row) {
      this.$refs.confirmedModal.showModal(row);
    },
    // formatDiff(diff) {
    //   console.log("44:", diff);
    //   // return ModalBase.formatDiff(diff);
    //   return Object.entries(diff).map(([k,v])=>{
    //     if (~['_t','_dt','_bef_dt','_at','_org','_id','_uid','_name','_src','_mapped','_at','modified','confirmed'].indexOf(k)) return;
    //     if (k === 'src_not_found') {
    //       if (v === true) return `<span class="badge badge-light">${k}</span> <span class="badge badge-danger">원본정보 사라짐</span>`;
    //       else if (v.deleted === true) return `<span class="badge badge-light">${k}</span> <span class="badge badge-success">원본정보 발견됨</span>`;
    //     }
    //     if (k === 'options') {
    //       if (utils.typeOf(v) !== 'array') return;
    //       return `<span class="badge badge-light">${k}</span><br/>` + v.map(e=>{
    //         if (~Object.keys(e).indexOf('created')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-success">옵션 생성됨</span> <small>${e.created.optnm}</small>:<span class="badge badge-light">${e.created.Size}</span> <small>재고</small>:<span class="badge badge-success">${e.created.stock}</span> <small>price</small>:<small>${utils.rnc(e.created.goods_price,2)}</small>`;
    //         if (~Object.keys(e).indexOf('deleted')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-danger">옵션 삭제됨</span> <small>${e.deleted.optnm}</small>:<span class="badge badge-light">${e.deleted.Size}</span> <small>재고</small>:<span class="badge badge-success">${e.deleted.stock}</span> <small>price</small>:<small>${utils.rnc(e.deleted.goods_price,2)}</small>`;
    //         if (e.Size) {
    //           let str = `&nbsp;&nbsp;&nbsp;<span class="badge badge-secondary">옵션 변경됨</span> <small>사이즈</small><span class="badge badge-light">${e.Size}</span><br/>`;
    //           str += Object.entries(e).filter(([k])=>k!=='Size').map(([k,v])=> {
    //             if (~Object.keys(v).indexOf('created')) return `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="badge badge-light">${k}</span> <span class="badge badge-success">생성됨</span> <small>${v.created}</small>`;
    //             if (~Object.keys(v).indexOf('deleted')) return `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="badge badge-light">${k}</span> <span class="badge badge-danger">삭제됨</span> <small>${v.deleted}</small>`;
    //             return `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="badge badge-light">${k}</span> <span class="badge badge-secondary">변경됨</span> '${v.updated_from}' ⇒ '${v.updated_to}'`;
    //           }).join('<br/>');
    //           return str;
    //         }
    //       }).join('<br/>');
    //     }
    //     if (k === 'img_urls') {
    //       if (utils.typeOf(v) !== 'array') return;
    //       return `<span class="badge badge-light">${k}</span><br/>` + v.map(e=> {
    //         if (~Object.keys(e).indexOf('created')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-success">이미지 생성됨</span> <a href="${e.created}" target="_blank">${e.created}</a>`;
    //         if (~Object.keys(e).indexOf('deleted')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-danger">이미지 삭제됨</span> <a href="${e.deleted}" target="_blank">${e.deleted}</a>`;
    //       }).join('<br/>');
    //     }
    //     if (~Object.keys(v).indexOf('created')) {
    //       return `<span class="badge badge-light">${k}</span> <span class="badge badge-success">생성됨</span> '${v.created}'`;
    //     } else if (~Object.keys(v).indexOf('deleted')) {
    //       return `<span class="badge badge-light">${k}</span> <span class="badge badge-danger">제거됨</span> '${v.deleted}'`;
    //     } else if (~Object.keys(v).indexOf('updated_from')) {
    //       return `<span class="badge badge-light">${k}</span> <span class="badge badge-secondary">변경됨</span> '${v.updated_from}' ⇒ '${v.updated_to}'`;
    //     }
    //     if (utils.typeOf(v[0]) === 'object') {
    //
    //     }
    //   }).filter(e=>e).join('<br/>');
    // },
    exclude(item) {
      this.form.goods_no_exclude += ' ' + item.goods_no;
    },
    async setDisplayStatusModal() {
      let selectedItems = this.items.list.filter(e=>e.selected);
      if (!selectedItems.length) return alert('상태를 변경할 상품을 선택해주세요');
      this.displayStatusReason = '';
      this.modal.displayStatus = true;
    },
    async setDisplayStatus() {
      if (!this.displayStatusReason) return alert('변경사유를 입력해주세요');
      if (this.items.list.filter(e=>e.selected).some(e=>e.manual) && !confirm('파트너 직접관리상품이 포함되어 있습니다. 노출상태를 변경하시겠습니까?')) return;
      let j = await postJson('/goods/setDisplayStatus', {form: {goods_no: this.items.list.filter(e=>e.selected).map(e=>e.goods_no),
          display_status: this.displayStatus, relay: this.displayStatusRelay, reason: this.displayStatusReason}});
      if (j) {
        alert('작업이 백그라운드에서 진행됩니다.');
      }
    },
    async imageProcessingModal() {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('이미지를 재처리할 상품을 선택해주세요');
      this.modal.image = true;
    },
    async imageProcessing(goods_no) {
      // if (!goods_no && this.items.list.filter(e=>e.selected).some(e=>e.manual) && !confirm('파트너 직접관리상품이 포함되어 있습니다. 진행하시겠습니까?')) return;
      let j = await postJson('/goods/requestImageProcessing', {goods_nos: goods_no ? [goods_no] : this.items.list.filter(e=>e.selected).map(e=>e.goods_no), intent: this.imageIntent, ignoreManual: true});
      if (j) {
        alert(`${j.cnt} 개 상품에 대해 이미지 작업이 시작되었습니다.` + (j.errors.length ? `\n${j.errors.length} 개의 에러가 발생했습니다:\n${j.errors.map(e=>e.error).join('\n')}` : ''));
        this.modal.image = false;
      }
    },
    async syncGodoModal() {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('발란몰로 싱크할 상품을 선택해주세요');
      this.modal.godo = true;
      this.syncForce = false;
    },
    async syncGodo(goods_no) {
      let j = await postJson('/goods/syncGodo', {goods_nos: goods_no ? [goods_no] : this.items.list.filter(e=>e.selected).map(e=>e.goods_no), cols:this.selectedGodoCols, force:this.syncForce});
      if (j) {
        utils.alert(`${goods_no.length} 개 상품이 싱크되었습니다.`);
        this.modal.godo = false;
      }
    },
    btnAction(row, event) {
      if (event === 'show_modal') {
        this.showModal(row);
      }
    },
    async syncStockMulti() {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('재고를 싱크할 상품을 선택해주세요');
      this.busy.syncStockMulti = true;
      let j = await postJson(`/goods/syncStockMulti`, {goods_nos: selected.map(e=>e.goods_no), renew: true});
      this.busy.syncStockMulti = false;
      if (j) {
        this.$modal.show({title:'갱신요청 성공', html: '작업이 시작되었습니다. 백그라운드에서 진행됩니다.'});
      }
    },
    async terminateGoods() {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('종료상태를 전파할 상품을 선택해주세요');
      this.busy.terminateGoods = true;
      let j = await postJson(`/goods/terminateGoods`, {goods_nos: selected.map(e=>e.goods_no)});
      this.busy.terminateGoods = false;
      if (j) {
        this.$modal.show({title:'성공', html: '상품의 종료상태가 전파되었습니다'});
      }
    },
    async removeGdGoods() {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('발란몰에서 제거할 상품을 선택해주세요');
      this.busy.removeGdGoods = true;
      let j = await postJson(`/goods/removeGdGoods`, {goods_nos: selected.map(e=>e.goods_no)});
      this.busy.removeGdGoods = false;
      if (j) {
        this.$modal.show({title:'성공', html: '상품이 제거되었습니다'});
      }
    },
    picClick(e) {
      e.selected = !e.selected;
      console.log(e);
      this.$forceUpdate();
    },
    recalcPicFilteredCnt() {
      this.picFilteredCnt = this.items.list.filter(e=>e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
    },
    jsonModal(title, json) {
      this.$modal.show({title, type:'json', item:json});
    },
    preDown(type) {
      if (!this.$R(['BALAANEER', 'E_GOODS_R'])) return this.downPartner(type);
      let selectedItems = this.items.list.filter(e=>e.selected);
      if (!selectedItems.length) return alert('다운받을 상품을 선택해주세요');
      let baseFields = {};
      Object.entries(CONFIRMED_COLUMNS).forEach(([k,v])=>{
        if (v.use !== false) baseFields[k] = v.name;
      });
      baseFields = {
        ...baseFields,
        'options.0.Size':'사이즈:0',
        'options.0.goods_consumer':'소비자가:0',
        'options.0.goods_price':'판매가:0',
        'options.0.stock':'재고:0',
        'img_urls.0':'이미지',
      };
      let dupFields = {
        basePrice:'기본현지가',
        localPrice:'세전현지가',
        befTaxPriceWithoutDelivery:'세전KRW',
        localDeliveryCost:'해외배송비KRW',
        befTaxPrice:'배송비포함KRW',
        cTax:'관세',
        idvTax:'개소세',
        eduTax:'교육세',
        idvEduTax:'개소세교육세합',
        vatKrw:'부가세',
        vatPrice:'세후가격',
        rebatePrice:'판매수수료포함가격',
        pgFee:'PG수수료',
        aftPgPrice:'PG수수료포함가격',
        domestic_delivery_cost:'국내배송비',
        finalPrice:'국내가격',
        finalPriceRoundUp:'국내가격올림',
        finalPriceWithoutVatPg:'VAT&PG제외가격',
      };
      let lastFields = {
        customs_rate:'관세율',
        vat:'부가세율',
        idvEduRate:'개소세교육세율',
        pgRate:'PG수수료',
        rebate:'판매수수료KRW',
        befMargin:'수정전마진',
        margin:'수정마진',
        marginRate:'수정마진율',
        discountRate:'할인율',
        finalPrice:'최종판매가',
        finalPriceRoundUp:'최종판매가RoundUp',
        finalMargin:'최종마진',
        finalMarginRate:'최종마진율',
      };
      let fields = [Object.keys(baseFields).join(',')].concat('consumer,sale,supply'.split(',').map(e=>Object.keys(dupFields).map(k=>`price_table.0.${e}.${k}`).join(','))).concat(Object.keys(lastFields).map(k=>`price_table.0.${k}`).join(',')).join(',');
      let header = [Object.values(baseFields).join(',')].concat('consumer,sale,supply'.split(',').map(e=>Object.values(dupFields).map(k=>`${e}_${k}`).join(','))).concat(Object.values(lastFields).join(',')).join(',');

      // down(selectedItems, header.split(','), fields.split(','), `Confirmed_${utils.dt()}`, type);
      this.xlsx.keys = fields.split(',');
      this.xlsx.labels = header.split(',');

      return true;
    },
    downPartner(type) {
      // 다양한 데이터를 다양한 구성으로 다양한 타입으로 다운받는다.
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('다운받을 상품을 선택해주세요');
      let keys = 'goods_no,goods_id,goods_nm,category,category_nm,brand_nm,brand_nm_kr,brand_no,sku_id,origin,season,color,description,composition,measurements,modelsize,optnm,md_name,shop,shop_id,category_code,consumer,price,discount_rate,tot_stock,crawl_url,stock_status,goods_status,display_status,created_dt,confirmed_dt,registered_dt,updated_dt'.split(',')
        , values = keys.map(e=>CONFIRMED_COLUMNS[e]);
      let name = values.map(e=>e.name);
      let code = keys, codeMap = {}; keys.forEach(e=>codeMap[e]=e);
      let optnm_idx = fields.indexOf('optnm'); // 옵션명
      name.splice(optnm_idx + 1, 0, '옵션');
      code.splice(optnm_idx + 1, 0, 'options');
      selected.forEach(e=>{
        e.options.map(o=>`${o.Size}||${o.goods_consumer}||${o.goods_price}||${o.stock}`).join('^^');
      });
      let rows = [codeMap, ...selected];
      down(rows, name, code, `${'Confirmed'}_${utils.dt()}`, type);
    },
    copyGoodsNo() {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('복사할 상품을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.map(e=>e.goods_no).join('\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },
  }
}
</script>

<style>
.cf_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.cf_desc {word-break: break-word;}
.cf_desc img {
  width: 100%;
  max-width: 300px;
}
.cf_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

.mp_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.mp_desc {word-break: break-word;}
.mp_desc img {
  width: 100%;
  max-width: 300px;
}
.mp_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

</style>
